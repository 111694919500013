export const DEPLOY_TYPES = {
  LOAN_IN_GIVE: 'LOAN_IN_GIVE',
  LOAN_IN_RETURN: 'LOAN_IN_RETURN',
  LOAN_OUT_GIVE: 'LOAN_OUT_GIVE',
  LOAN_OUT_RETURN: 'LOAN_OUT_RETURN',
  SALARY: 'SALARY',
  TAXES: 'TAXES',
  INTERNAL_TRANSFER: 'INTERNAL_TRANSFER',
  INVOICE_PAYMENT: 'INVOICE_PAYMENT',
  OTHER: 'OTHER',
};

export const REVIEW_STATUSES = {
  QUEUED: 'QUEUED',
  PROCESSING: 'PROCESSING',
  CHANGES_REQUESTED: 'CHANGES_REQUESTED',
  APPROVED: 'APPROVED',
  NONE: 'NONE',
};
