import React from 'react';
import moment from 'moment';
import {
  TextField,
  ChipField,
  ReferenceField,
  SingleFieldList,
  ArrayField,
  BooleanField,
  SimpleShowLayout,
  Show,
  Datagrid,
  NumberField,
  FunctionField,
  UrlField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import { Card, CardHeader, Stack } from '@mui/material';
import TextArrayField from 'components/fields/TextArrayField';

const timestampToDDMMYYYY = (timestamp) => {
  const val = moment(timestamp);
  return val.isValid() ? val.format('DD/MM/YYYY') : null;
};

const urlStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
};

const DeployShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Deploy
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="title" label="Title" />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <BooleanField source="enabled" label="Enabled" />
          </SimpleShowLayout>
        </Stack>
      </Card>
      <Stack direction="row" spacing={2} p={2}>
        <Stack spacing={2} maxWidth="250px">
          <h3>General</h3>
          <SimpleShowLayout>
            <TextField source="githubOrg" label="Github Organization" />
            <UrlField source="credentials.JIRA_BASE_URL" label="Jira URL" />
            <TextField source="credentials.JIRA_API_USER" label="Jira API User" />
            <UrlField source="slackWebHook" label="Slack Webhook" style={urlStyle}/>
            <TextArrayField source="projects">
              <SingleFieldList>
                <ChipField source="id" />
              </SingleFieldList>
            </TextArrayField>
            <ArrayField source="channels">
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ArrayField>
          </SimpleShowLayout>
        </Stack>
        <Stack spacing={2}>
          <h3>Envs</h3>
          <SimpleShowLayout>
            <TextField source="defaultDeployEnv" label="Default deploy env" />
            <ArrayField source="deployEnvs">
              <Datagrid bulkActionButtons={null}>
                <TextField source="repo" label="Repo" />
                <TextField source="label" label="Label" />
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        </Stack>
        <Stack spacing={2}>
          <h3>Repos</h3>
          <SimpleShowLayout>
            <ArrayField source="repos">
              <Datagrid bulkActionButtons={null}>
                <TextField source="repo" label="Repo" />
                <BooleanField source="tagsSupported" label="Tags supported" />
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        </Stack>
      </Stack>
    </Stack>
  </Show>
);

export default DeployShow;
