import React, { createContext, PureComponent, useCallback, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { exploreGithubRepos, exploreJiraProjects } from 'utils/api';
import { debounce, get } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormContext } from 'react-hook-form';
import { Alert, Button, Card, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';

const ExplorerContext = createContext();

const RESOURCES = [
  {
    key: 'jira',
    load: async (credentials) => {
      try {
        const { baseUrl, apiToken, apiUser } = credentials;
        const jiraProjects = await exploreJiraProjects({ baseUrl, apiToken, apiUser });
        return jiraProjects.map((project) => ({
          title: `[${project.key}] ${project.name}`,
          subtitle: `${project.projectTypeKey} Jira project`,
          item: project,
        }));
      } catch (e) {
        return null;
      }
    },
  },
  {
    key: 'github',
    load: async (credentials) => {
      try {
        const { githubToken, githubOrg } = credentials;
        const githubRepos = await exploreGithubRepos({ githubToken, githubOrg });
        return githubRepos.map((repo) => ({
          title: repo.full_name,
          subtitle: `${repo.visibility} Github repository`,
          item: repo,
        }));
      } catch (e) {
        return null;
      }
    },
  },
];

export class ExplorerProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      exploredData: {},
      credentials: {},
    };
    this.ctx = {
      setCredentials: this.handleSetCredentials,
      explore: this.handleExploreResourcesDebounced,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.credentials !== this.state.credentials) {
      this.handleExploreResourcesDebounced();
    }
  }

  handleSetCredentials = (resourceKey, newCredentials) => this.setState((prevState) => ({
    ...prevState,
    credentials: {
      ...prevState.credentials,
      [resourceKey]: newCredentials,
    },
  }));

  handleSetExploredData = (resourceKey, exploredResourceData) => this.setState((prevState) => ({
    ...prevState,
    exploredData: {
      ...prevState.exploredData,
      [resourceKey]: exploredResourceData,
    },
  }));

  handleExploreResourcesDebounced = debounce(async (key = undefined) => {
    let resources = RESOURCES;
    if (key) {
      const keys = Array.isArray(key) ? key : [key];
      resources = resources.filter(({ key: resourceKey }) => keys.includes(resourceKey));
    }
    await Promise.all(resources.map(async (resource) => {
      const { credentials } = this.state;
      const credentialsForResource = credentials[resource.key] || {};
      const explored = await resource.load(credentialsForResource);
      this.handleSetExploredData(resource.key, explored);
    }));
  }, 1000, { leading: false, trailing: true });

  render() {
    const { children } = this.props;

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const ctx = { ...this.ctx, exploredData: this.state.exploredData };

    return (
      <ExplorerContext.Provider value={ctx}>
        {children}
      </ExplorerContext.Provider>
    );
  }
}

ExplorerProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useExplorer = () => useContext(ExplorerContext);

export const CredentialsFormListener = ({
  resourceKey,
  extractCredentials,
  formDependencies,
}) => {
  const formCtx = useFormContext();
  const { setCredentials } = useExplorer();

  const buildFormDependencies = useCallback((values, keys) => {
    const extractedValues = keys.map((key) => get(values, key));
    return extractedValues;
  }, []);

  const builtFormDependencies = buildFormDependencies(
    formCtx.getValues(),
    formDependencies,
  );
  const extractedCredentials = useMemo(() => {
    const values = formCtx.getValues();
    return extractCredentials(values);
  }, builtFormDependencies);

  useEffect(() => {
    setCredentials(resourceKey, extractedCredentials);
  }, [extractedCredentials]);

  return null;
};

export const ExploredResourceList = ({
  resourceKey,
  transform,
  title,
  maxElements = 5,
}) => {
  const { exploredData, explore } = useExplorer();

  const exploredDataSection = exploredData?.[resourceKey];
  const transformedData = transform ? transform(exploredDataSection) : exploredDataSection;

  const arrayFound = Array.isArray(transformedData);

  return (
    <Card>
      <Stack p={2} spacing={2}>
        <Stack direction="row">
          <Typography>{title}</Typography>
          <Button onClick={() => explore(resourceKey)}>Refresh</Button>
        </Stack>
        <If condition={!arrayFound}>
          <Alert severity="error">No array found</Alert>
        </If>
        <If condition={arrayFound}>
          <List dense={false}>
            {transformedData.slice(0, maxElements).map((data) => (
              <ListItem>
                <ListItemText
                  primary={data?.title}
                  secondary={data?.subTitle || null}
                />
              </ListItem>
            ))}
          </List>
        </If>
      </Stack>
    </Card>
  );
};
